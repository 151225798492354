import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, Select,InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses, MenuItem } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import MuiToggleButton from '@mui/material/ToggleButton';	
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import useInnerTable from "./components/useInnerTable";
import axios from "./axios";
import {axios as Axios }  from "axios";
import useToken from "./useToken";
import Visibility from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GlobalAlert from "./GlobalAlert";
import PuffLoader from "react-spinners/PuffLoader"; 
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import TimezoneSelect from 'react-timezone-select'

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function API ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {RefreshToken} = props;
  const {token} = useToken();
  const { getAlertTitle} = GlobalAlert();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  const [showUpdateBrivoButton, setshowUpdateBrivoButton] = React.useState(false);
  const [EnLog,setEnLog] = useState([]);
  const [customFieldArr, setCustomFieldArr] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupPDK, setOpenPopupPDK] = useState(false);
  const [openPopupUser, setOpenPopupUser] = useState(false);
  const [recordsBridge, setRecordsBridge] = useState([]);	
  const [recordsCamera, setRecordsCamera] = useState([]);	
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();


  const getDisableValueEdit = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("54").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("54").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("54").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("54").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("54").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("54").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("54").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("54").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValue = (val) => {
    if(getDisableValueEdit() == false){
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("54");
        //console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("54");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("54");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].distributor_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("54");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("54");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].dealer_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("54");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("54");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].admin_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("54");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
  }else{
    return true;
  }
    
  }
  const headCellsBrige = [	
    { id: 'name', label: 'Friendly Bridge Name' },	
    { id: 'bridge_id', label: 'Bridge ID'},	
    { id: 'action', label: 'Action', disableSorting: true },	
  ];	
  const headCellsCamera = [	
    { id: 'name', label: 'Friendly Camera Name' },	
    { id: 'camera_id', label: 'Camera ID'},	
    { id: 'action', label: 'Action', disableSorting: true },	
  ];	
  const [filterFnBridge, setFilterFnBridge] = useState({ fn: items => { return items; } });	
  const [filterFnCamera, setFilterFnCamera] = useState({ fn: items => { return items; } });
  const [records,setRecords] = useState([]);
  const [apiValue, setAPIValue] = useState({ouath_client:"",oauth_secret:"",lpr_accesss:false,elevator_accesss:false,brivo_enable:false,brivo_last_sync:"",pdk_enable:false,pdk_last_sync:"",
  brivo_username: "",
  brivo_password: "",
  brivo_clientid: "",
  brivo_clientsecret: "",
  brivo_mobileid:"",
  brivo_mobilesecret:"",
  pdk_organization_id:"",
  pdk_system_id:"",
  brivo_apikey:"",
  app_disable:"0",
  "issub":token.issub,"subId":token.subid,lpr_limit:5,lpr_guest_limit:5,open_park:0});
  const headCells = [
    { id: 'name', label: 'Field Name', disableSorting: true },
    { id: 'email', label: 'Field Type', disableSorting: true },
    { id: 'access', label: 'Action', disableSorting: true },
  ];
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const {
    TblContainer,
    TblHead,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne ,
  } = useInnerTable(records, headCells, filterFn,5,650);
  let [loading, setLoading] = useState(true);
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/getprofile/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email
       },
      });
      console.log( res.data[0]);
      let ad  = apiValue;
      ad.ouath_client = res.data[0].ouath_client;
      ad.oauth_secret = res.data[0].oauth_secret;
      ad.lpr_accesss = Boolean(res.data[0].lpr_accesss);
      ad.elevator_accesss = Boolean(res.data[0].elevator_accesss);
      ad.brivo_enable = Boolean(res.data[0].brivo_enable);
      ad.pdk_enable = Boolean(res.data[0].pdk_enable);
      ad.brivo_last_sync =res.data[0].brivo_last_sync;
      ad.pdk_last_sync =res.data[0].pdk_last_sync;
      ad.brivo_username =res.data[0].brivo_username;
      ad.brivo_password =res.data[0].brivo_password;
      ad.brivo_clientid =res.data[0].brivo_clientid;
      ad.brivo_clientsecret =res.data[0].brivo_clientsecret;
      ad.brivo_mobileid =res.data[0].brivo_mobileid;
      ad.brivo_mobilesecret =res.data[0].brivo_mobilesecret;
      ad.brivo_apikey = res.data[0].brivo_apikey;
      ad.lpr_limit =res.data[0].lpr_limit;
      ad.lpr_guest_limit =res.data[0].lpr_guest_limit;
      ad.open_park = res.data[0].open_park;	
      ad.app_disable = res.data[0].app_disable;	
      ad.pdk_organization_id = res.data[0].pdk_organization_id;	
      ad.pdk_system_id = res.data[0].pdk_system_id;
      setRecordsBridge(res.data[0].bridge);	
      setRecordsCamera(res.data[0].camera);
     /* if(Array.isArray(JSON.parse(res.data[0].custom_field))){
        setCustomFieldArr(JSON.parse(res.data[0].custom_field));
      }*/
      console.log(ad);
      setAPIValue(ad);
      
      setEnLog(res.data);
      setloadingbtn(false);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };

  const getCustomField = async () => {
    
    try {
      const res = await axios.get("/admin/getcustomfield/"+token.id,{
        headers: { 'Content-Type':  'application/json' ,
        'token' : token.token,
        'tokenid' : token.email},
      });
      console.log( res.data[0]);
      setRecords( res.data);
      setloadingbtn(false);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  }

  const updateMyPostDataPDK = async () => {
    setLoading(true);
    try {
      
      let i = apiValue.pdk_enable? 1 : 0;
      let j = apiValue.brivo_enable? 1 : 0;
      console.log('i value '+i + ' j valu '+j);
      const res = await axios.patch("/admin/updateapipdk/"+token.id,{"pdk_enable":i,"brivo_enable":j,"issub":token.issub,"subId":token.subid}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('PCDUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      getMyPostData();
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('PCDUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const updateMyPostDataBrivo = async () => {
    setLoading(true);
    try {
      
      let i = apiValue.pdk_enable? 1 : 0;
      let j = apiValue.brivo_enable? 1 : 0;
      console.log('i value '+i + ' j valu '+j);
      const res = await axios.patch("/admin/updateapibrivo/"+token.id,{"pdk_enable":i,"brivo_enable":j,"issub":token.issub,"subId":token.subid}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('PCDUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      getMyPostData();
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('PCDUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const updateAPIPostData = async () => {	
    setLoading(true);	
    try {	
      const res = await axios.patch("/admin/globalsettings/"+token.id,apiValue, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',	
      'token' : token.token,	
      'tokenid' : token.email }});	
      console.log( res.data);	
      setOpenSnackData(getAlertTitle('PCDUS'));	
      setSeverityData("success");	
      setOpenSnack(true);	
      setLoading(false);	
      setshowUpdateBrivoButton(false);	
      setshowUpdateButton(false);	
      setOpenPopup(false);	
      getMyPostData();	
      	
    } catch (error) {	
      console.log(error.response);	
      setLoading(false);	
      setOpenSnackData(getAlertTitle('PCDUF'));	
      setSeverityData("error");	
      setshowUpdateButton(false);	
      setOpenSnack(true);	
      if(error.response.data.message == 'UnAuthorized'){	
        RefreshToken();	
			}	
    }	
  };

  const updateAPIPostDataELE = async () => {	
    setLoading(true);	
    try {	
      const res = await axios.patch("/admin/eleapiaccess/"+token.id,apiValue, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',	
      'token' : token.token,	
      'tokenid' : token.email }});	
      console.log( res.data);	
      setOpenSnackData(getAlertTitle('PCDUS'));	
      setSeverityData("success");	
      setOpenSnack(true);	
      setLoading(false);	
      setshowUpdateBrivoButton(false);	
      setshowUpdateButton(false);	
      setOpenPopup(false);	
      getMyPostData();	
      	
    } catch (error) {	
      console.log(error.response);	
      setLoading(false);	
      setOpenSnackData(getAlertTitle('PCDUF'));	
      setSeverityData("error");	
      setshowUpdateButton(false);	
      setOpenSnack(true);	
      if(error.response.data.message == 'UnAuthorized'){	
        RefreshToken();	
			}	
    }	
  };
  const updateAPIPostDataLPR = async () => {	
    setLoading(true);	
    try {	
      const res = await axios.patch("/admin/lprapiaccess/"+token.id,apiValue, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',	
      'token' : token.token,	
      'tokenid' : token.email }});	
      console.log( res.data);	
      setOpenSnackData(getAlertTitle('PCDUS'));	
      setSeverityData("success");	
      setOpenSnack(true);	
      setLoading(false);	
      setshowUpdateBrivoButton(false);	
      setshowUpdateButton(false);	
      setOpenPopup(false);	
      getMyPostData();	
      	
    } catch (error) {	
      console.log(error.response);	
      setLoading(false);	
      setOpenSnackData(getAlertTitle('PCDUF'));	
      setSeverityData("error");	
      setshowUpdateButton(false);	
      setOpenSnack(true);	
      if(error.response.data.message == 'UnAuthorized'){	
        RefreshToken();	
			}	
    }	
  };
  const updateMyBrivoPostData = async () => {
    setLoading(true);
    try {
      const res = await axios.patch("/admin/editbrivocredential/"+token.id,apiValue, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('BACUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateBrivoButton(false);
      setOpenPopup(false);
      getMyPostData();
      
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      setOpenSnackData(getAlertTitle('BACUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };

  
  const updateMyPDKPostData = async () => {
    console.log(" now api value is "+ JSON.stringify(apiValue));
    setLoading(true);
    try {
      const res = await axios.patch("/admin/editpdkcredential/"+token.id,apiValue, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('PACUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateBrivoButton(false);
      setOpenPopup(false);
      getMyPostData();
      
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      setOpenSnackData(getAlertTitle('PACUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  
  const handlePDKAPIChange = () => {
    updateMyPostDataPDK();
  }
  const handleBRIVOAPIEnableChange = () => {
    updateMyPostDataBrivo();
  }
  const handleLPRELESaveData = () => {	
    updateAPIPostData();	
  }
  const handleELESaveData = () => {	
    updateAPIPostDataELE();	
  }
  const handleLPRUpdateData = () => {
    updateAPIPostDataLPR();
  }
  const handleSaveBrivoData = () => {
    updateMyBrivoPostData();
  }
  const handleSavePDKData = () => {
    updateMyPDKPostData();
  }
  const handleSyncBrivo = async() => {
    setloadingbtn(true);
    fetch("https://mdvaccess.com/php/syncbrivo.php?pid="+token.id)
    .then( async  response => {
        console.log( response);
        const data = await response.json();
        console.log( "data os "+data.mydoorview);
        //console.log( "data os "+response.message);
        setOpenSnackData(data.mydoorview);
        setSeverityData("success");
        setOpenSnack(true);
        setLoading(false);
        setshowUpdateBrivoButton(false);
        setOpenPopup(false);
        getMyPostData();
      })
     
      .catch(error => {
        console.log(error);
        setLoading(false);
        setOpenSnackData(getAlertTitle('BASF'));
        setSeverityData("error");
        setOpenSnack(true);
      }
    ).finally(() => {
     console.log('data is called');
     setloadingbtn(false);
  })
    
  }

  const handleBrivoMobilePass = async() => {
    setloadingbtn(true);
    fetch("https://mdvaccess.com/php/setupbrivopassaccess.php?pid="+token.id)
    .then( async  response => {
        console.log( response);
        const data = await response.json();
        console.log( "data os "+data.mydoorview);
        //console.log( "data os "+response.message);
        setOpenSnackData(data.mydoorview);
        setSeverityData("success");
        setOpenSnack(true);
        setLoading(false);
        setshowUpdateBrivoButton(false);
        setOpenPopup(false);
        getMyPostData();
      })
     
      .catch(error => {
        console.log(error);
        setLoading(false);
        setOpenSnackData(getAlertTitle('BASF'));
        setSeverityData("error");
        setOpenSnack(true);
      }
    ).finally(() => {
     console.log('data is called');
     setloadingbtn(false);
  })
    
  }
  const handleFullSyncBrivo = async() => {
    setloadingbtn(true);
    fetch("https://mdvaccess.com/php/syncfullbrivo.php?pid="+token.id)
    .then( async  response => {
        console.log( response);
        const data = await response.json();
        console.log( "data os "+data.mydoorview);
        //console.log( "data os "+response.message);
        setOpenSnackData(data.mydoorview);
        setSeverityData("success");
        setOpenSnack(true);
        setLoading(false);
        setshowUpdateBrivoButton(false);
        setOpenPopup(false);
        getMyPostData();
      })
     
      .catch(error => {
        console.log(error);
        setLoading(false);
        setOpenSnackData(getAlertTitle('BASF'));
        setSeverityData("error");
        setOpenSnack(true);
      }
    ).finally(() => {
     console.log('data is called');
     setloadingbtn(false);
  })
    
  }
  
  const handleSyncPDK = async() => {
    setloadingbtn(true);
    fetch("https://mdvaccess.com/php/syncpdk.php?pid="+token.id)
    .then( async  response => {
        console.log( response);
        const data = await response.json();
        console.log( "data os "+data.mydoorview);
        //console.log( "data os "+response.message);
        setOpenSnackData(data.mydoorview);
        setSeverityData("success");
        setOpenSnack(true);
        setLoading(false);
        setshowUpdateBrivoButton(false);
        setOpenPopup(false);
        getMyPostData();
      })
     
      .catch(error => {
        console.log(error);
        setLoading(false);
        setOpenSnackData(getAlertTitle('PASF'));
        setSeverityData("error");
        setOpenSnack(true);
      }
    ).finally(() => {
     console.log('data is called');
     setloadingbtn(false);
  })
    
  }
  // NOTE:  calling the function
  useEffect(() => {
    getMyPostData();
    getCustomField();
  }, []);
  
  const handleInputChange = (event) => {
    let ds = event.target.name;
    apiValue[ds] = event.target.value;
    setshowUpdateBrivoButton(true);
    setAPIValue({ouath_client:apiValue.ouath_client,oauth_secret:apiValue.oauth_secret,lpr_accesss:apiValue.lpr_accesss,elevator_accesss:apiValue.elevator_accesss,brivo_enable:apiValue.brivo_enable,brivo_last_sync:apiValue.brivo_last_sync,pdk_enable:apiValue.pdk_enable,pdk_last_sync:apiValue.pdk_last_sync,
      brivo_username: apiValue.brivo_username,
      brivo_password:  apiValue.brivo_password,
      brivo_clientid:  apiValue.brivo_clientid,
      brivo_mobileid: apiValue.brivo_mobileid,
      brivo_mobilesecret: apiValue.brivo_mobilesecret,
      lpr_limit:apiValue.lpr_limit,
      lpr_guest_limit:apiValue.lpr_guest_limit,
      open_park : apiValue.open_park,
      brivo_apikey : apiValue.brivo_apikey,
      app_disable : apiValue.app_disable,
      pdk_organization_id : apiValue.pdk_organization_id,
      pdk_system_id : apiValue.pdk_system_id,
      brivo_clientsecret:  apiValue.brivo_clientsecret,"issub":token.issub,"subId":token.subid});
  }
  const handlecheckboxCheck=(event) =>{
    console.log(apiValue);
    let ds = event.target.name;
    apiValue[ds] = event.target.checked;
    setshowUpdateButton(true);
    setAPIValue({ouath_client:apiValue.ouath_client,oauth_secret:apiValue.oauth_secret,lpr_accesss:apiValue.lpr_accesss,elevator_accesss:apiValue.elevator_accesss,brivo_enable:apiValue.brivo_enable,brivo_last_sync:apiValue.brivo_last_sync,pdk_enable:apiValue.pdk_enable,pdk_last_sync:apiValue.pdk_last_sync,
      brivo_username: apiValue.brivo_username,
      brivo_password:  apiValue.brivo_password,
      brivo_clientid:  apiValue.brivo_clientid,
      brivo_mobileid: apiValue.brivo_mobileid,
      brivo_mobilesecret: apiValue.brivo_mobilesecret,
      lpr_limit:apiValue.lpr_limit,
      lpr_guest_limit:apiValue.lpr_guest_limit,
      open_park : apiValue.open_park,
      brivo_apikey : apiValue.brivo_apikey,
      app_disable : apiValue.app_disable,
      pdk_organization_id : apiValue.pdk_organization_id,
      pdk_system_id : apiValue.pdk_system_id,
      brivo_clientsecret:  apiValue.brivo_clientsecret,"issub":token.issub,"subId":token.subid});
  }
  const groupAccessName =['Beta Tester','James Home','Everyone On The list'];
  const [isVisible,setIsVisibile] = useState(false);
  const [isVisibleSecret,setIsVisibileSecret] = useState(false);
  const [seeSecrete,setSeeSecrete] = useState(false);
  let [currentType,setCurrentType] = useState('');
  const customFieldMenu = {
    id: "1",
    label: "Select Custom Field Type",
    items: [
      {
        label:"Date",
        callback: () =>  {
          setCurrentType("Date")
        }
      },
      {
        label:"Text",
        callback: () =>  {
         
          setCurrentType("Text")
        }
      },
      {
        label:"Number",
        callback: () =>  {
          setCurrentType("Number")
        }
      },
      
    ]
  };
  const PinLengthHolder = {
    id: "1",
    label: "5",
    items: [
      {
        label: "1",
        callback: () =>  {}
      },
      {
        label: "2",
        callback: () =>  {}
      }
      ,
      {
        label: "3",
        callback: () =>  {}
      }
      ,
      {
        label: "4",
        callback: () =>  {}
      }
      ,
      {
        label: "5",
        callback: () =>  {}
      }
      ,
      {
        label: "6",
        callback: () =>  {}
      }
      ,
      {
        label: "7",
        callback: () =>  {}
      }
      ,
      {
        label: "8",
        callback: () =>  {}
      }
      ,
      {
        label: "9",
        callback: () =>  {}
      }
      ,
      {
        label: "10",
        callback: () =>  {}
      }
    ]
  }
  const ToggleButton = styled(MuiToggleButton)(({ selectedColor, unSelectedColor }) => ({	
    width: 'auto',	
    height: '2.46rem',	
    color: 'white',	
    backgroundColor: unSelectedColor,	
    fontWeight: '700',	
            fontSize: '0.93rem',	
            textTransform: 'capitalize',	
            	
            alignItems: 'center',	
            color: '#FFFFFF',	
            fontFamily: "Lato",	
            borderRadius:'0.31rem',	
    '&.Mui-selected, &.Mui-selected:hover': {	
      color: 'white',	
      backgroundColor: selectedColor,	
      	
    },	
    '&:hover': {	
      color: 'white',	
      backgroundColor: unSelectedColor,	
      	
    },	
  }));
  const [currentDeleteID,setCurrentDeleteId] = useState();
  const [loadingbtn,setloadingbtn] = useState(false);
  const [customFieldName,setcustomFieldName] = useState('');
  const [deleteCustomFieldName,setDeleteCustomFieldName] = useState('');
  const [openDelete, setOpenDelete] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const handleDelete = (event) => {
    setCurrentDeleteType("Custom");
    let idofItem =  records.filter(x => x.id == event.target.name);
    setCurrentDeleteId(event.target.name);
    setDeleteCustomFieldName(idofItem[0].name);
    setTitleforalert("Are you sure you want to delete custom field "+ idofItem[0].name + " ? \nAlso you are aware that the data associated with this custom filed will also be deleted for all user and you can't undo it after delete. \n Do you want to proceed?");
    setOpenDelete(true);
  }
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
    console.log("currentDeleteID"+currentDeleteID);
    if(currentDeleteType == "Custom"){	
      deleteMyPostData();	
      console.log("Custom click");	
      }	
      if(currentDeleteType == "Bridge"){	
        deleteMyBridgeData();	
        console.log("bridge click");	
      }	
      if(currentDeleteType == "Camera"){	
        deleteMyCameraData();	
        console.log("camera click");	
      }
  }
  const deleteMyBridgeData = async () => {	
    setloadingbtn(true);	
     try {	
       const res = await axios.delete("/admin/deletebridge/"+token.id+"/"+currentDeleteID, 	
       {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,	
       'token' : token.token,	
       'tokenid' : token.email,	
       'issub':token.issub,	
       'subId':token.subid,	
       'bridgename':deleteBrigeName}});	
       console.log( res.data);	
       setOpenSnackData("Bridge Deleted Successfully.");	
       setSeverityData("success");	
       setOpenSnack(true);	
       setLoading(false);	
       	
       getMyPostData();	
     } catch (error) {	
       console.log(error.message);	
       setLoading(false);	
       setOpenSnackData("Bridge Delete Failed, Please Try Again Later");	
       setSeverityData("error");	
       setOpenSnack(true);	
       setloadingbtn(false);	
       if(error.response.data.message == 'UnAuthorized'){	
        RefreshToken();	
			}	
     }	
   };	
   const deleteMyCameraData = async () => {	
    setloadingbtn(true);	
     try {	
       const res = await axios.delete("/admin/deletecamera/"+token.id+"/"+currentDeleteID, 	
       {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,	
       'token' : token.token,	
       'tokenid' : token.email,	
       'issub':token.issub,	
       'subId':token.subid,	
       'cameraname':deleteCameraName}});	
       console.log( res.data);	
       setOpenSnackData("Camera Deleted Successfully.");	
       setSeverityData("success");	
       setOpenSnack(true);	
       setLoading(false);	
       	
       getMyPostData();	
     } catch (error) {	
       console.log(error.message);	
       setLoading(false);	
       setOpenSnackData("Camera Delete Failed, Please Try Again Later");	
       setSeverityData("error");	
       setOpenSnack(true);	
       setloadingbtn(false);	
       if(error.response.data.message == 'UnAuthorized'){	
        RefreshToken();	
			}	
     }	
   };
  const deleteMyPostData = async () => {
    setloadingbtn(true);
     try {
       const res = await axios.delete("/admin/deletecustomfield/"+token.id+"/"+currentDeleteID, 
       {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
       'token' : token.token,
       'tokenid' : token.email,
       'issub':token.issub,
       'subId':token.subid,
       'customfname':deleteCustomFieldName}});
       console.log("token id is "+ token.id+ " token is " + token.token + "token id "+token.email);
       console.log( res.data);
       setOpenSnackData(getAlertTitle('CFDS'));
       setSeverityData("success");
       setOpenSnack(true);
       setLoading(false);
       
       getCustomField();
     } catch (error) {
       console.log(error.message);
       setLoading(false);
       setOpenSnackData(getAlertTitle('CFDF'));
       setSeverityData("error");
       setOpenSnack(true);
       setloadingbtn(false);
       if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
     }
   };
  const handleCustomFieldChange = (event) =>{
    setcustomFieldName(event.target.value);
  }
  const [brideName,setBridgeName] = useState('');		
  const BridgeInputChange = (event) => {		
    setBridgeName(event.target.value);		
  }		
  const [brideId,setBridgeId] = useState('');		
  const BridgeIdInputChange = (event) => {		
    setBridgeId(event.target.value);		
  }		
  const handleAddBridge = () => {		
    if(brideName.length > 0 && brideId.length > 0){		
      addBridePost();		
   }		
   else if(brideName.length <= 0){		
     setOpenSnackData(getAlertTitle('EULPNNE'));		
     setSeverityData("error");		
     setOpenSnack(true);		
   }		
   else{		
    setOpenSnackData(getAlertTitle('EULPNNE'));		
    setSeverityData("error");		
    setOpenSnack(true);		
   }		
  }		
  const addBridePost = async () => {		
    console.log("In update post data");		
    setLoading(true);		
    try {		
      const res = await axios.post("/admin/addbridge/"+token.id,{name:brideName,bridge_id:brideId,pid:token.id,issub:token.issub,subId:token.subid}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',		
      'token' : token.token,		
      'tokenid' : token.email }});		
      console.log( res.data);		
      setOpenSnackData(res.data.rows);		
      setSeverityData("success");		
      setOpenSnack(true);		
      //setLoading(false);		
      //setshowUpdateButton(false);		
      getMyPostData();		
     		
    } catch (error) {		
      console.log(error.response.data);		
      setLoading(false);		
      setOpenSnackData("Bridge Add Failed, Please Try Again Later.");		
      setSeverityData("error");		
      setOpenSnack(true);		
      if(error.response.data.message == 'UnAuthorized'){		
        RefreshToken();		
      }		
    }		
  };		
  const [cameraName,setCameraName] = useState('');		
  const CameraInputChange = (event) => {		
    setCameraName(event.target.value);		
  }		
  const [cameraId,setCameraId] = useState('');		
  const CameraIdInputChange = (event) => {		
    setCameraId(event.target.value);		
  }		
  const handleAddCamera = () => {		
    if(cameraName.length > 0 && cameraId.length > 0){		
      addCameraPost();		
   }		
   else if(cameraName.length <= 0){		
     setOpenSnackData(getAlertTitle('EULPNNE'));		
     setSeverityData("error");		
     setOpenSnack(true);		
   }		
   else{		
    setOpenSnackData(getAlertTitle('EULPNNE'));		
    setSeverityData("error");		
    setOpenSnack(true);		
   }		
  }		
  const addCameraPost = async () => {		
    console.log("In update post data");		
    setLoading(true);		
    try {		
      const res = await axios.post("/admin/addcamera/"+token.id,{name:cameraName,camera_id:cameraId,pid:token.id,issub:token.issub,subId:token.subid}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',		
      'token' : token.token,		
      'tokenid' : token.email }});		
      console.log( res.data);		
      setOpenSnackData(res.data.rows);		
      setSeverityData("success");		
      setOpenSnack(true);		
      //setLoading(false);		
      //setshowUpdateButton(false);		
      getMyPostData();		
     		
    } catch (error) {		
      console.log(error.response.data);		
      setLoading(false);		
      setOpenSnackData("Camera Add Failed, Please Try Again Later.");		
      setSeverityData("error");		
      setOpenSnack(true);		
      if(error.response.data.message == 'UnAuthorized'){		
        RefreshToken();		
      }		
    }		
  };		
  const {		
    TblContainer : TblContainerBridge,		
    TblHead :  TblHeadBridge,		
    TblPagination : TblPaginationBridge,		
    BelowTblPagination : BelowTblPaginationBridge,		
    recordsAfterPagingAndSorting : recordsAfterPagingAndSortingBridge,		
    setPageToNewOne : setPageToNewOneBridge		
  } = useInnerTable(recordsBridge, headCellsBrige, filterFnBridge,5,350);		
  const {		
    TblContainer : TblContainerCamera,		
    TblHead :  TblHeadCamera,		
    TblPagination : TblPaginationCamera,		
    BelowTblPagination : BelowTblPaginationCamera,		
    recordsAfterPagingAndSorting : recordsAfterPagingAndSortingCamera,		
    setPageToNewOne : setPageToNewOneCamera		
  } = useInnerTable(recordsCamera, headCellsCamera, filterFnCamera,5,350);		
  		  
  const [currentDeleteType, setCurrentDeleteType] = useState('');		
  const [deleteBrigeName, setDeleteBridgeName] = useState('');		
  const [deleteCameraName, setDeleteCameraName] = useState('');		
  const handleBridgeDelete = (val,val2) =>{		
    setTitleforalert("Are you sure you want to delete Bridge "+ val2 + " ?");		
    setCurrentDeleteId(val);		
    setDeleteBridgeName(val2);		
    setCurrentDeleteType('Bridge');		
    console.log(val);		
    setOpenDelete(true);		
  }		
  const handleCameraDelete = (val,val2) =>{		
    setTitleforalert("Are you sure you want to delete Camera "+ val2 + " ?");		
    setCurrentDeleteId(val);		
    setDeleteCameraName(val2);		
    setCurrentDeleteType('Camera');		
    console.log(val);		
    setOpenDelete(true);		
  }		
  const handleBridgeModify = (event) => {		
   /* if(token.issub == true){		
      if(token.name != 'SUPER ADMIN'){		
      setOpenSnackData("You don't have access to perform any action on this page");		
      setSeverityData("error");		
      setOpenSnack(true);		
      return;		
      }		
    }*/		
    let idIn;		
    for(let i = 0; i < recordsBridge.length ; i++){		
      if(recordsBridge[i].id == event.target.name){		
          idIn = i;		
          break;		
      }		
    }		
   		
     		
    if((recordsBridge[idIn].name.trim()).length <= 0){	
          setOpenSnackData("Friendly Bridge Name Can't Be Null, Please Enter Friendly Bridge Name");	
          setSeverityData("error");	
          setOpenSnack(true);	
          return;	
         	
    }	
    if((recordsBridge[idIn].bridge_id.trim()).length <= 0){	
      setOpenSnackData("Bridge Id Can't Be Null, Please Enter Bridge Id");	
      setSeverityData("error");	
      setOpenSnack(true);	
      return;	
     	
    }	
    let asv = { name:recordsBridge[idIn].name,bridge_id:recordsBridge[idIn].bridge_id,id:recordsBridge[idIn].id,issub:token.issub,subId:token.subid};	
    updateMyBridgeData(asv);	
        	
  }	
  const updateMyBridgeData = async (val) => {	
    console.log(apiValue);	
    setloadingbtn(true);	
    try {	
      const res = await axios.patch("/admin/updatebridge/"+token.id,val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',	
      'token' : token.token,	
      'tokenid' : token.email }});	
      console.log( res.data);	
      setOpenSnackData("Bridge Modified Successfully.");	
      setSeverityData("success");	
      setOpenSnack(true);	
      setLoading(false);	
      setshowUpdateButton(false);	
      getMyPostData();	
    } catch (error) {	
      console.log(error.response);	
      setLoading(false);	
      setOpenSnackData("Bridge Modify Failed, Please Try Again Later.");	
      setSeverityData("error");	
      setOpenSnack(true);	
      setloadingbtn(false);	
      if(error.response.data.message == 'UnAuthorized'){	
        RefreshToken();	
			}	
    }	
  };	
  const handleCameraModify = (event) => {	
    /*if(token.issub == true){	
      if(token.name != 'SUPER ADMIN'){	
      setOpenSnackData("You don't have access to perform any action on this page");	
      setSeverityData("error");	
      setOpenSnack(true);	
      return;	
      }	
    }*/	
    let idIn;	
    for(let i = 0; i < recordsCamera.length ; i++){	
      if(recordsCamera[i].id == event.target.name){	
          idIn = i;	
          break;	
      }	
    }	
   	
     	
    if((recordsCamera[idIn].name.trim()).length <= 0){	
          setOpenSnackData("Friendly Camera Name Can't Be Null, Please Enter Friendly Camera Name");	
          setSeverityData("error");	
          setOpenSnack(true);	
          return;	
         	
    }	
    if((recordsCamera[idIn].camera_id.trim()).length <= 0){	
      setOpenSnackData("Camera Id Can't Be Null, Please Enter Camera Id");	
      setSeverityData("error");	
      setOpenSnack(true);	
      return;	
     	
    }	
    let asv = { name:recordsCamera[idIn].name,camera_id:recordsCamera[idIn].camera_id,id:recordsCamera[idIn].id,issub:token.issub,subId:token.subid};	
    updateMyCameraData(asv);	
        	
  }	
  const updateMyCameraData = async (val) => {	
    console.log(apiValue);	
    setloadingbtn(true);	
    try {	
      const res = await axios.patch("/admin/updatecamera/"+token.id,val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',	
      'token' : token.token,	
      'tokenid' : token.email }});	
      console.log( res.data);	
      setOpenSnackData("Camera Modified Successfully.");	
      setSeverityData("success");	
      setOpenSnack(true);	
      setLoading(false);	
      setshowUpdateButton(false);	
      getMyPostData();	
    } catch (error) {	
      console.log(error.response);	
      setLoading(false);	
      setOpenSnackData("Camera Modify Failed, Please Try Again Later.");	
      setSeverityData("error");	
      setOpenSnack(true);	
      setloadingbtn(false);	
      if(error.response.data.message == 'UnAuthorized'){	
        RefreshToken();	
			}	
    }	
  };
  const [parkingStatus,setParkingStatus] = useState('open');	
  const handleChangeParking = (event, newAlignment) => {	
    //setParkingStatus(newAlignment);	
   // let ds = event.target.name;	
    apiValue.open_park = newAlignment;	
    setshowUpdateButton(true);	
    setAPIValue({ouath_client:apiValue.ouath_client,oauth_secret:apiValue.oauth_secret,lpr_accesss:apiValue.lpr_accesss,elevator_accesss:apiValue.elevator_accesss,brivo_enable:apiValue.brivo_enable,brivo_last_sync:apiValue.brivo_last_sync,pdk_enable:apiValue.pdk_enable,pdk_last_sync:apiValue.pdk_last_sync,	
      brivo_username: apiValue.brivo_username,	
      brivo_password:  apiValue.brivo_password,	
      brivo_clientid:  apiValue.brivo_clientid,	
      brivo_mobileid: apiValue.brivo_mobileid,
      brivo_mobilesecret: apiValue.brivo_mobilesecret,
      lpr_limit:apiValue.lpr_limit,	
      lpr_guest_limit:apiValue.lpr_guest_limit,	
      open_park : apiValue.open_park,	
      brivo_apikey : apiValue.brivo_apikey,	
      app_disable : apiValue.app_disable,
      pdk_organization_id : apiValue.pdk_organization_id,
      pdk_system_id : apiValue.pdk_system_id,
      brivo_clientsecret:  apiValue.brivo_clientsecret,"issub":token.issub,"subId":token.subid});	
  };	
  const handleTableBridgeNameChange = (event) => {	
    console.log('event happen');	
    let idIn;	
    for(let i = 0; i < recordsBridge.length ; i++){	
      let item = recordsBridge[i];	
      	
      if(item.id == event.target.name ){	
       	
          idIn = i;	
          break;	
      }	
    }	
    console.log(event.target.name);	
    console.log(idIn );	
    recordsBridge[idIn].name = event.target.value;	
    setRecordsBridge(recordsBridge);	
    	
  }	
  const handleTableBridgeIdChange = (event) => {	
    let idIn;	
    for(let i = 0; i < recordsBridge.length ; i++){	
      let item = recordsBridge[i];	
      	
      if(item.id == event.target.name ){	
       	
          idIn = i;	
          break;	
      }	
    }	
    console.log(event.target.name);	
    console.log(idIn );	
    recordsBridge[idIn].bridge_id = event.target.value;	
    setRecordsBridge(recordsBridge);	
  }	
  const handleTableCameraNameChange = (event) => {	
    console.log('event happen');	
    let idIn;	
    for(let i = 0; i < recordsCamera.length ; i++){	
      let item = recordsCamera[i];	
      	
      if(item.id == event.target.name ){	
       	
          idIn = i;	
          break;	
      }	
    }	
    console.log(event.target.name);	
    console.log(idIn );	
    recordsCamera[idIn].name = event.target.value;	
    setRecordsCamera(recordsCamera);	
    	
  }	
  const handleTableCameraIdChange = (event) => {	
    let idIn;	
    for(let i = 0; i < recordsCamera.length ; i++){	
      let item = recordsCamera[i];	
      	
      if(item.id == event.target.name ){	
       	
          idIn = i;	
          break;	
      }	
    }	
    console.log(event.target.name);	
    console.log(idIn );	
    recordsCamera[idIn].camera_id = event.target.value;	
    setRecordsCamera(recordsCamera);	
  }       	
  const updateCustomField = async (val) => {
    setloadingbtn(true);
    try {
      const res = await axios.post("/admin/addcustomfield/"+token.id,val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log("token id is "+ token.id+ " token is " + token.token + "token id "+token.email);
      setOpenSnackData(getAlertTitle('CFUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      getCustomField();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      setOpenSnackData(getAlertTitle('CFUF'));
      setSeverityData("error");
      setOpenSnack(true);
      
    }
  };

  
  const handleAddCustomField = () => {

    if((customFieldName.trim()).length <= 0){
      setOpenSnackData(getAlertTitle('CFANNE'));
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
    if((currentType.trim()).length <= 0){
      setOpenSnackData(getAlertTitle('CFATNE'));
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
    //let asv = {username:records[idIn].username,email:records[idIn].email,password:records[idIn].password,id:records[idIn].id,user_id:records[idIn].user_id};
    //let custArr = customFieldArr;
    let myCustData = { name: customFieldName , type : currentType,"issub":token.issub,"subId":token.subid};
    console.log('cust data ',myCustData);
    //customFieldArr.push(myCustData);
    //console.log(customFieldArr);
    //setCustomFieldArr(customFieldArr);
    //let custData = {custom_field:customFieldArr};
    //console.log("custData" +custData.toString());
    updateCustomField(myCustData);


  }
  const updateAppStatus = async (val) => {	
    console.log(apiValue);	
    setloadingbtn(true);	
    try {	
      const res = await axios.patch("/admin/updateappstatus/"+token.id,{ app_disable:val,issub:token.issub,subId:token.subid}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',	
      'token' : token.token,	
      'tokenid' : token.email }});	
      console.log( res.data);	
      setOpenSnackData("MyDoorView App Access Modified Successfully.");	
      setSeverityData("success");	
      setOpenSnack(true);	
      setLoading(false);	
      setshowUpdateButton(false);	
      getMyPostData();	
    } catch (error) {	
      console.log(error.response);	
      setLoading(false);	
      setOpenSnackData("MyDoorView App Access Modify Failed, Please Try Again Later.");	
      setSeverityData("error");	
      setOpenSnack(true);	
      setloadingbtn(false);	
      if(error.response.data.message == 'UnAuthorized'){	
        RefreshToken();	
			}	
    }	
  };
  const handlecheckboxCheckReverse =(event) =>{
    apiValue.app_disable = event.target.checked == true ? "0" : "1";
    updateAppStatus(apiValue.app_disable)
   /* setAPIValue({ouath_client:apiValue.ouath_client,oauth_secret:apiValue.oauth_secret,lpr_accesss:apiValue.lpr_accesss,elevator_accesss:apiValue.elevator_accesss,brivo_enable:apiValue.brivo_enable,brivo_last_sync:apiValue.brivo_last_sync,pdk_enable:apiValue.pdk_enable,pdk_last_sync:apiValue.pdk_last_sync,	
      brivo_username: apiValue.brivo_username,	
      brivo_password:  apiValue.brivo_password,	
      brivo_clientid:  apiValue.brivo_clientid,	
      lpr_limit:apiValue.lpr_limit,	
      lpr_guest_limit:apiValue.lpr_guest_limit,	
      open_park : apiValue.open_park,	
      brivo_apikey : apiValue.brivo_apikey,	
      app_disable : apiValue.app_disable,
      brivo_clientsecret:  apiValue.brivo_clientsecret,"issub":token.issub,"subId":token.subid});	*/
  }

  const [currentTab,setCurrentTab] = useState(0);
  const handleTabClick =(val) =>{
    setshowUpdateButton(false);
    setCurrentTab(val);
  }
  const ShowLog = () =>{
    return(<>
     <Box sx={{
      display: { xs: "none", md: "flex" },
      }}>
    <Grid container spacing={2}> 
      <Grid item >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Admin ID </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Brivo Password </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Brivo Client ID </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>Brivo Client Secret </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>API Key </Typography>
      </Stack>
      </Grid>
      <Grid item >
      <Stack direction="column"  spacing={2}>
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="brivo_username"
                       value={apiValue.brivo_username}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= {isVisible ? 'text' : 'password'}
                       name="brivo_password"
                       value={apiValue.brivo_password}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                           endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    style={{
                                        color:
                                        'rgba(135, 135, 135, 0.5)',
                                        padding: '18px 5px 0 0',
                                    }}
                                    aria-label={
                                        'visibility token'
                                    }
                                    onClick={() => {setIsVisibile(!isVisible)}}
                                >
                                   
                                   {isVisible ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                                    
                                </IconButton>
                            </InputAdornment>
                        ),
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= 'text'
                       name="brivo_clientid"
                       value={apiValue.brivo_clientid}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= {isVisibleSecret ? 'text' : 'password'}
                       name="brivo_clientsecret"

                       value={apiValue.brivo_clientsecret}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                           endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    style={{
                                        color:
                                        'rgba(135, 135, 135, 0.5)',
                                        padding: '18px 5px 0 0',
                                    }}
                                    aria-label={
                                        'visibility token'
                                    }
                                    onClick={() => {setIsVisibileSecret(!isVisibleSecret)}}
                                >
                                   
                                   {isVisibleSecret ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                                    
                                </IconButton>
                            </InputAdornment>
                        ),
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= 'text'
                       name="brivo_apikey"
                       value={apiValue.brivo_apikey}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />
                   
      </Stack>
      </Grid>
      
      </Grid></Box>
      <Box sx={{
      display: { xs: "flex", md: "none" },
      }}>
    <Stack  direction="row"> 
        
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Admin ID </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Brivo Password </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Brivo Client ID </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>Brivo Client Secret </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>API Key </Typography>
      </Stack>
      
      <Stack direction="column"  spacing={2}>
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="brivo_username"
                       value={apiValue.brivo_username}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= {isVisible ? 'text' : 'password'}
                       name="brivo_password"
                       value={apiValue.brivo_password}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                           endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    style={{
                                        color:
                                        'rgba(135, 135, 135, 0.5)',
                                        padding: '18px 5px 0 0',
                                    }}
                                    aria-label={
                                        'visibility token'
                                    }
                                    onClick={() => {setIsVisibile(!isVisible)}}
                                >
                                   
                                   {isVisible ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                                    
                                </IconButton>
                            </InputAdornment>
                        ),
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= 'text'
                       name="brivo_clientid"
                       value={apiValue.brivo_clientid}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= {isVisibleSecret ? 'text' : 'password'}
                       name="brivo_clientsecret"

                       value={apiValue.brivo_clientsecret}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                           endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    style={{
                                        color:
                                        'rgba(135, 135, 135, 0.5)',
                                        padding: '18px 5px 0 0',
                                    }}
                                    aria-label={
                                        'visibility token'
                                    }
                                    onClick={() => {setIsVisibileSecret(!isVisibleSecret)}}
                                >
                                   
                                   {isVisibleSecret ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                                    
                                </IconButton>
                            </InputAdornment>
                        ),
                       }}
                      
                   />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name="brivo_apikey"
                       value={apiValue.brivo_apikey}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />
      </Stack>
      
      
      </Stack></Box>
       {showUpdateBrivoButton && <Button sx={mainThemeStyle.saveButton} onClick={handleSaveBrivoData}>Save Brivo Credentials</Button>}
       </>)
  }
  
  return <> 
  <Box sx={{position: 'absolute',
           width:'auto',
           height: '280px',
           top:'50px',
           left: '38px',
           display: { xs: "none", md: 'flex' },
           right: '38px',
           flexGrow: 1,
          
           }}>
             {/* <Stack direction="column" spacing={0}> */}
                        <Stack direction="row" spacing={2} >
                  
           <Typography sx={mainThemeStyle.lableContainsBig}>MyDoorView App Access :</Typography>

<FormControlLabel  sx={ {height:'100px'}} 
        control={<IOSSwitch sx={{ m: 1 }} name="disabled" disabled = {getDisableValue("245")}  onChange={handlecheckboxCheckReverse} checked={apiValue.app_disable == "1" ? false : true } />}
        label=""
      />{apiValue.app_disable == '0' && <Typography sx={mainThemeStyle.lableContainsBigGreen}>ENABLED</Typography>}
      {apiValue.app_disable == '1' && <Typography sx={mainThemeStyle.lableContainsBigRed}>DISABLED</Typography>}
                        </Stack>
                       
                        </Box>
        
                        <Box sx={{position: 'absolute',
                 width:'auto',
                 height: '100px',
                 top:'50px',
                 left: '14px',
                 display: { xs: "flex", md: 'none' },
                 right: '14px',
                 flexGrow: 1,
                 justifyContent:"center"
                
                 }}>
                   {/* <Stack direction="column" spacing={0}> */}
                              <Stack direction="row" spacing={2} justifyContent="center">
                        
                 <Typography sx={mainThemeStyle.lableContainsBig}>MyDoorView App Access :</Typography>
      
      <FormControlLabel  sx={ {height:'65px'}} 
              control={<IOSSwitch sx={{ m: 1 }} name="disabled" disabled = {getDisableValue("245")}  onChange={handlecheckboxCheckReverse} checked={apiValue.app_disable == "1" ? false : true } />}
              label=""
            /> {apiValue.app_disable == '0' && <Typography sx={mainThemeStyle.lableContainsBigGreen}>ENABLED</Typography>}
            {apiValue.app_disable == '1' && <Typography sx={mainThemeStyle.lableContainsBigRed}>DISABLED</Typography>}
                              </Stack>
                              
                              </Box>
  <Box sx={mainThemeStyle.boxTopHeader}>
      {(currentTab == 0 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(0)}} active>MDV</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>PDK</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>BRIVO</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR </Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Elevator Control</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Global Settings</Button>
  </Stack>)}
  {(currentTab == 1 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>MDV</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(1)}}>PDK</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>BRIVO</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR </Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Elevator Control</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Global Settings</Button>
  </Stack>)}
  {(currentTab == 2 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>MDV</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>PDK</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(2)}}>BRIVO</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Elevator Control</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Global Settings</Button>
  </Stack>)}
  {(currentTab == 3 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>MDV</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>PDK</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>BRIVO</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Elevator Control</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Global Settings</Button>
  </Stack>)}
  {(currentTab == 4 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} >MDV</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>PDK</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>BRIVO</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(4)}} active>Elevator Control</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}} >Global Settings</Button>
  </Stack>)}
  {(currentTab == 5 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} >MDV</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>PDK</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>BRIVO</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Elevator Control</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(5)}} active>Global Settings</Button>
  </Stack>)}
  
</Box>
<Box sx={{position: 'absolute',
           width:'auto',
          
           top:'100px',
           left: '5px',
           display: { xs: "flex", md: "none" },
           right: '5px',
           flexGrow: 1,
          
           }}>
  {(currentTab == 0 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(0)}} active>MDV</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>PDK</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>BRIVO</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Elevator Control</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Global Settings</Button>
    </Grid> </Grid>)}
  {(currentTab == 1 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>MDV</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(1)}}>PDK</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>BRIVO</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Elevator Control</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Global Settings</Button>  </Grid> </Grid>)}
  {(currentTab == 2 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>MDV</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>PDK</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(2)}}>BRIVO</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Elevator Control</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Global Settings</Button>  </Grid> </Grid>)}
  {(currentTab == 3 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>MDV</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>PDK</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>BRIVO</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Elevator Control</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Global Settings</Button>  </Grid> </Grid>)}
  {(currentTab == 4 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} >MDV</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>PDK</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>BRIVO</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(4)}}>Elevator Control</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Global Settings</Button>   </Grid> </Grid>)}

    {(currentTab == 5 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} >MDV</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>PDK</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>BRIVO</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Elevator Control</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(5)}}>Global Settings</Button>   </Grid> </Grid>)}
           </Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   {currentTab == 0 && "Manage MDV API"}
                   {currentTab == 1 && "Manage PDK API"}
                   {currentTab == 2 && "Manage BRIVO API"}
                   {currentTab == 3 && "Manage LPR API"}
                   {currentTab == 4 && "Manage Elevator Control"}	
                   {currentTab == 5 && "Manage Global Settings"}
                   </Typography>
               </div>
              
           </div>
           
              
           <Divider sx={{backgroundColor:'white'}} />
           
           {(currentTab == 0 &&  <>
            <Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Auth API Client ID </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40} > Auth API Client Secret </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> License Plate Recognition(LPR) API </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Elevator Control API </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable BRIVO API </Typography> */}
     {/*  {showUpdateButton && <Button sx={mainThemeStyle.normalButton} onClick={handleSaveCardFormat}>Save Changes</Button>}    */}
      </Stack>
      
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> { apiValue.ouath_client }</Typography>
     
     
      {/* <Checkbox  sx={mainThemeStyle.formCheckBox} name="lpr_accesss"  disabled   checked={apiValue.lpr_accesss} />
      <Checkbox  sx={mainThemeStyle.formCheckBox}  name="elevator_accesss"  disabled  checked={apiValue.elevator_accesss}/>
      <Stack direction="row"  spacing={2}>
      <Checkbox  sx={mainThemeStyle.formCheckBox}name="brivo_enable"  onChange={handlecheckboxCheck} checked={apiValue.brivo_enable}/>
      <Button  sx={mainThemeStyle.normalButtonHeight} onClick={handleSyncBrivo}>Sync Brivo API</Button>
      </Stack> */}
    
      
     
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40} > Auth API Client Secret </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}> Brivo API Last Sync Time </Typography>
      <Button  sx={mainThemeStyle.normalButtonHeight} onClick={() =>{setOpenPopup(true)}}>Set Brivo API Credentials</Button>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Enable PDK API </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>PDK API Last Sync Time </Typography>
       <Button  sx={mainThemeStyle.normalButtonHeight} onClick={() =>{ getCustomField(); setOpenPopupUser(true);}}>Set Up Custom Field For User</Button> 
    */}   </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={seeSecrete ? mainThemeStyle.popUpLableAnswerfixedWidth :mainThemeStyle.popUpLableAnswer} height={40} onMouseOver={() => {
          setSeeSecrete(true);
          
      }} onClick={() => {navigator.clipboard.writeText(apiValue.oauth_secret);}} onMouseOut={() => {
        setSeeSecrete(false);
      }}>{seeSecrete ?  apiValue.oauth_secret : '*******************'}</Typography>
       {/* 
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.brivo_last_sync}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}></Typography>
      <Stack direction="row"  spacing={2}>
      <Checkbox  sx={mainThemeStyle.formCheckBox} name="pdk_enable"  onChange={handlecheckboxCheck} checked={apiValue.pdk_enable} />
      
      </Stack>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.pdk_last_sync}</Typography> */}
      </Stack>
      </Grid>
     
      
      </Grid>
       
      <div style={{ display: 'flex' }}>
        <Stack direction='row' spacing={2} >
                           <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>Add Custom Field
                           </Typography>
                           <TextField
                id="text"
                type="text"
              placeholder="Custom Field Name"
              onChange={handleCustomFieldChange}
               variant="filled"
               sx={mainThemeStyle.InputMenuItem}
               value={customFieldName}
                                InputLabelProps={{ shrink: true }
                              }
                              disabled = {getDisableValue("209")}
                                  style={{paddingTop: '16px'}}
                                InputProps={{
                                   
                                    sx: {
                                        
                                        color:'rgba(135, 135, 135, 0.5)',
                                        height:'33px',
                                        borderRadius: '5px',
                                        backgroundColor: '#121212',
                                        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                        padding: '0 0 18px 0',
                                        textIndent: '15px !important',
                                        '&.Mui-disabled': {
             

                                          background: 'rgba(135, 135, 135, 0.5)',
                                        },
                                        
                                        "& input.Mui-disabled": {
                                          color: "white"
                                        }
                                       
                                        
                                    },
                                }}
                               
              />
               <NestedButtonDropdown
                    itemId={customFieldMenu.id} // NOTE: itemId is required for track items
                    variant="contained"
                    disabled = {getDisableValue("209")}
                    sx={{ color: 'rgba(135, 135, 135, 0.5)',
                    height:'34px',
                    borderRadius: '5px',
                    marginTop:'15px',
                    marginLeft:'5px',
                    marginRight:'5px',
                    backgroundColor: '#121212',
                    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                    padding: '5px 0 5px 0',
                    textIndent: '15px !important',}}
                    data={customFieldMenu}
                  />
              {records.length < 5 && <LoadingButton sx={mainThemeStyle.normalButtonDown} loading={loadingbtn} onClick={handleAddCustomField} disabled = {getDisableValue("209")}> Add </LoadingButton>}
              {records.length >= 5 && <LoadingButton sx={mainThemeStyle.normalButtonDown} disabled loading={loadingbtn} onClick={handleAddCustomField} > Add </LoadingButton>}
              </Stack></div>
              <Typography sx={mainThemeStyle.popUpWarner} height={40} marginTop={2}>Note: You may add up to 5 custom fields</Typography>
              <Divider sx={{backgroundColor:'white'}} />
        
              <TblContainer>
                            <TblHead />
        
                <TableBody>
                 {recordsAfterPagingAndSorting().map(item => (
                    <TableRow sx={{ borderBottom: "1.5px solid black",
                  }}>
                     
                      <TableCell align="left"  sx={mainThemeStyle.cellRow}> {item.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {item.type} </TableCell>
              
                      
                      <TableCell align="left">
                         {/*  <Stack direction='row' spacing={2}>
                          <Typography></Typography>  */} 
                       {/* <LoadingButton sx={mainThemeStyle.viewLogButton} name={item.id} loading={loadingbtn} onClick={handlePropertyModify}> Save Changes </LoadingButton>   */}
                        <LoadingButton loading={loadingbtn} name={item.id} sx={mainThemeStyle.viewLogButtonRed} onClick={handleDelete} disabled = {getDisableValue("210")}>Delete</LoadingButton>
                         {/*  </Stack> */}  </TableCell>
                    </TableRow>
                   ))}
                </TableBody>
                </TblContainer>
                </Box>
                <Box sx={{display: { xs: "block", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Auth API Client ID </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Auth API Client Secret </Typography>
      </Stack>
      
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> { apiValue.ouath_client }</Typography>
      <Typography  sx={seeSecrete ? mainThemeStyle.popUpLableAnswerfixedWidth :mainThemeStyle.popUpLableAnswer} height={40} onMouseOver={() => {
          setSeeSecrete(true);
          
      }} onClick={() => {navigator.clipboard.writeText(apiValue.oauth_secret);}} onMouseOut={() => {
        setSeeSecrete(false);
      }}>{seeSecrete ?  apiValue.oauth_secret : '*******************'}</Typography>
     
      </Stack>
      </Grid>
     
      </Grid>
       
      <Grid padding= '10px' paddingBottom='32px' paddingTop='32px'>
        <Stack direction='column' spacing={2}>
        <Stack direction='row' spacing={2} >
                           <Typography  sx={
                            { fontFamily: 'Lato',
                            fontWeight: '500',
                            fontSize: '1rem',
                            lineHeight: '1.37rem',
                            marginTop:'1rem',
                            color:'#fff',}
                           } height={40}>Add Custom Field
                           </Typography>
                           <TextField
                id="text"
                type="text"
              placeholder="Custom Field Name"
              disabled = {getDisableValue("209")}
              onChange={handleCustomFieldChange}
               variant="filled"
               sx={mainThemeStyle.InputMenuItem}
               value={customFieldName}
                                InputLabelProps={{ shrink: true }
                              }
                                  style={{paddingTop: '16px'}}
                                InputProps={{
                                   
                                    sx: {
                                        
                                        color:'rgba(135, 135, 135, 0.5)',
                                        height:'33px',
                                        borderRadius: '5px',
                                        backgroundColor: '#121212',
                                        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                        padding: '0 0 18px 0',
                                        textIndent: '15px !important',
                                        '&.Mui-disabled': {
             

                                          background: 'rgba(135, 135, 135, 0.5)',
                                        },
                                        
                                        "& input.Mui-disabled": {
                                          color: "white"
                                        }
                                       
                                        
                                    },
                                }}
                               
              /> </Stack>
              <Stack direction='row' spacing={2} >
               <NestedButtonDropdown
                    itemId={customFieldMenu.id} // NOTE: itemId is required for track items
                    variant="contained"
                    disabled = {getDisableValue("209")}
                    sx={{ color: 'rgba(135, 135, 135, 0.5)',
                    height:'34px',
                    borderRadius: '5px',
                    marginTop:'15px',
                    marginLeft:'5px',
                    marginRight:'5px',
                    lineHeight:'15px',
                    backgroundColor: '#121212',
                    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                    padding: '5px 0 5px 0',
                    textIndent: '15px !important',}}
                    data={customFieldMenu}
                  />
              {records.length < 5 && <LoadingButton sx={mainThemeStyle.normalButtonDown} loading={loadingbtn} disabled = {getDisableValue("209")} onClick={handleAddCustomField}> Add </LoadingButton>}
              {records.length >= 5 && <LoadingButton sx={mainThemeStyle.normalButtonDown} disabled loading={loadingbtn} onClick={handleAddCustomField}> Add </LoadingButton>}
              </Stack></Stack></Grid>
              <Typography sx={mainThemeStyle.popUpWarner} height={40} marginTop={2}>Note: You may add up to 5 custom fields</Typography>
              <Divider sx={{backgroundColor:'white'}} />
        
              <TblContainer>
                            <TblHead />
        
                <TableBody>
                 {recordsAfterPagingAndSorting().map(item => (
                    <TableRow sx={{ borderBottom: "1.5px solid black",
                  }}>
                     
                      <TableCell align="left"  sx={mainThemeStyle.cellRow}> {item.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {item.type} </TableCell>
              
                      
                      <TableCell align="left">
                         {/*  <Stack direction='row' spacing={2}>
                          <Typography></Typography>  */} 
                       {/* <LoadingButton sx={mainThemeStyle.viewLogButton} name={item.id} loading={loadingbtn} onClick={handlePropertyModify}> Save Changes </LoadingButton>   */}
                        <LoadingButton loading={loadingbtn} name={item.id} sx={mainThemeStyle.viewLogButtonRed} disabled = {getDisableValue("210")} onClick={handleDelete}>Delete</LoadingButton>
                         {/*  </Stack> */}  </TableCell>
                    </TableRow>
                   ))}
                </TableBody>
                </TblContainer>
                </Box>
          </>
      
      )}

{(currentTab == 1 &&  <>  <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Enable PDK API </Typography>
       {showUpdateButton && <Button sx={mainThemeStyle.normalButton} onClick={handlePDKAPIChange}>Save Changes</Button>}   
      </Stack>
      
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="row"  spacing={2}>
      <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  name="pdk_enable"  disabled = {getDisableValue("211")} onChange={handlecheckboxCheck} checked={apiValue.pdk_enable} />
      <LoadingButton  sx={mainThemeStyle.normalButtonHeight} loading={loadingbtn} onClick={handleSyncPDK}>Sync PDK API</LoadingButton> </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        
      <Typography sx={mainThemeStyle.popUpLable} height={40}>PDK API Last Sync Time </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
       
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.pdk_last_sync}</Typography> 
      <Button  sx={mainThemeStyle.normalButtonHeight} onClick={() =>{setOpenPopupPDK(true)}} >Set PDK API Credentials</Button>
      </Stack>
      </Grid>
     
      
      </Grid>
      </Box>

      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Enable PDK API </Typography>
        <Typography sx={mainThemeStyle.popUpLable} height={40}>PDK API Last Sync Time </Typography>
        <Button  sx={mainThemeStyle.normalButtonHeight} onClick={() =>{setOpenPopupPDK(true)}} >Set PDK API Credentials</Button>
        <LoadingButton  sx={mainThemeStyle.normalButtonHeight} loading={loadingbtn} onClick={handleSyncPDK}>Sync PDK API</LoadingButton>
          
      </Stack>
      
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Checkbox  sx={mainThemeStyle.formCheckBox} name="pdk_enable"  disabled = {getDisableValue("211")} onChange={handlecheckboxCheck} checked={apiValue.pdk_enable} />
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.pdk_last_sync}</Typography> 
      </Stack>
      </Grid>
    
      
      {showUpdateButton && <Button sx={{
          width: 'auto',
          height: '2.06rem',
          marginTop:'1.2rem',
          button: {
              textTransform: "none"
            },
          fontWeight: '700',
          fontSize: '0.93rem',
          marginLeft:'1rem',
          
          alignItems: 'center',
          color: '#FFFFFF',
          fontFamily: "Lato",
          borderRadius:'0.31rem',
         '&:hover': {
          backgroundColor: 'rgba(109, 195, 252, 1)',
         }
      }} onClick={handlePDKAPIChange}>Save Changes</Button>}
      
      </Grid>
      
      </Box>
      </>
      )}

{(currentTab == 2 &&  
<>
<Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Enable BRIVO API </Typography>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> BRIVO Mobile Pass Access</Typography>
       {showUpdateButton && <Button sx={mainThemeStyle.normalButton} onClick={handleBRIVOAPIEnableChange}>Save Changes</Button>}   
      </Stack>
      
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2.5}>
      <Stack direction="row"  spacing={2}>
     
      <Checkbox  sx={mainThemeStyle.formCheckBoxLow}name="brivo_enable"  disabled = {getDisableValue("212")} onChange={handlecheckboxCheck} checked={apiValue.brivo_enable}/>
      <LoadingButton  sx={mainThemeStyle.normalButtonHeight} loading={loadingbtn} onClick={handleSyncBrivo} disabled = {getDisableValue("213")}>Sync Brivo API</LoadingButton>
      {token.issub === true && token.name === 'SUPER ADMIN' && 
       <LoadingButton  sx={mainThemeStyle.normalButtonHeight} loading={loadingbtn} onClick={handleFullSyncBrivo} >Full Sync Brivo API
       </LoadingButton>
      
      }
      
      
      
      </Stack>
      
      {token.issub === true && token.name === 'SUPER ADMIN' && apiValue.brivo_mobileid == "" && apiValue.brivo_mobilesecret == "" && <LoadingButton  sx={mainThemeStyle.normalButtonHeight} loading={loadingbtn} onClick={handleBrivoMobilePass} >Set Up Brivo Mobile Pass Access
      </LoadingButton>}
      {token.name != 'SUPER ADMIN' && apiValue.brivo_mobileid == "" && apiValue.brivo_mobilesecret == "" && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> N/A </Typography>}
      {apiValue.brivo_mobileid != "" && apiValue.brivo_mobilesecret != "" && <Typography sx={mainThemeStyle.popUpLableAnswerGreen} height={40}> Active </Typography>}
      
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        
      <Typography sx={mainThemeStyle.popUpLable} height={40}>BRIVO API Last Sync Time </Typography>
      <Button  sx={mainThemeStyle.normalButtonHeight} onClick={() =>{setOpenPopup(true)}} disabled = {getDisableValue("214")}>Set Brivo API Credentials</Button>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
       
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.brivo_last_sync}</Typography>
      </Stack>
      </Grid>
     
     
      </Grid>
      </Box>

      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Enable BRIVO API </Typography>
        <Typography sx={mainThemeStyle.popUpLable} height={60}>BRIVO API Last Sync Time </Typography>
        <Typography sx={mainThemeStyle.popUpLable} height={120}> </Typography>
        
        <Typography sx={mainThemeStyle.popUpLable} height={60}>BRIVO Mobile Pass Access </Typography>
       
      </Stack>
      
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
     
      <Checkbox  sx={mainThemeStyle.formCheckBox}name="brivo_enable"  disabled = {getDisableValue("212")} onChange={handlecheckboxCheck} checked={apiValue.brivo_enable}/>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={60}> {apiValue.brivo_last_sync}</Typography>
      <Button sx={{
         width: 'auto',
         maxHeight: '2.5rem',
         marginTop:'0rem',
         button: {
             textTransform: "none"
           },
           fontWeight: '700',
           fontSize: '0.83rem',
           lineHeight:'0.93rem',
         alignItems: 'center',
         color: '#FFFFFF',
         fontFamily: "Lato",
         borderRadius:'0.31rem',
        '&:hover': {
         backgroundColor: 'rgba(109, 195, 252, 1)',
        }
      }} onClick={handleSyncBrivo} disabled = {getDisableValue("213")}>Sync Brivo API</Button>
      {token.issub === true && token.name === 'SUPER ADMIN' && 
       <LoadingButton  sx={mainThemeStyle.normalButtonHeight} loading={loadingbtn} onClick={handleFullSyncBrivo} >Full Sync Brivo API
       </LoadingButton>
      
      }
      <Button  sx={{
         width: 'auto',
         maxHeight: '2.5rem',
         marginTop:'0rem',
         button: {
             textTransform: "none"
           },
           fontWeight: '700',
           fontSize: '0.83rem',
           lineHeight:'0.93rem',
         alignItems: 'center',
         color: '#FFFFFF',
         fontFamily: "Lato",
         borderRadius:'0.31rem',
        '&:hover': {
         backgroundColor: 'rgba(109, 195, 252, 1)',
        }
      }} onClick={() =>{setOpenPopup(true)}} disabled = {getDisableValue("214")}>Set Brivo API Credentials</Button>
      
     
      {token.issub === true && token.name === 'SUPER ADMIN' && apiValue.brivo_mobileid == "" && apiValue.brivo_mobilesecret == "" && <LoadingButton  sx={mainThemeStyle.normalButtonHeight} loading={loadingbtn} onClick={handleBrivoMobilePass} >Set Up Brivo Mobile Pass Access
      </LoadingButton>}
      {token.name != 'SUPER ADMIN' && apiValue.brivo_mobileid == "" && apiValue.brivo_mobilesecret == "" && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> N/A </Typography>}
      {apiValue.brivo_mobileid != "" && apiValue.brivo_mobilesecret != "" && <Typography sx={mainThemeStyle.popUpLableAnswerGreen} height={40}> Active </Typography>}
      
      </Stack>
      </Grid>
     
      {showUpdateButton && <Button sx={{
          width: 'auto',
          height: '2.06rem',
          marginTop:'1.2rem',
          button: {
              textTransform: "none"
            },
          fontWeight: '700',
          fontSize: '0.93rem',
          marginLeft:'1rem',
          
          alignItems: 'center',
          color: '#FFFFFF',
          fontFamily: "Lato",
          borderRadius:'0.31rem',
         '&:hover': {
          backgroundColor: 'rgba(109, 195, 252, 1)',
         }
      }} onClick={handleBRIVOAPIEnableChange}>Save Changes</Button>} 
      </Grid>
     
      </Box>
      </>
      
      )}

{/* {(currentTab == 3 &&  <>
<Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> License Plate Recognition(LPR) API </Typography>
       {showUpdateButton && <Button sx={mainThemeStyle.normalButton} onClick={handleSaveCardFormat}>Save Changes</Button>}   
      </Stack>
      
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Checkbox  sx={mainThemeStyle.formCheckBoxLow} name="lpr_accesss"   disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true}   checked={apiValue.lpr_accesss} />
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Elevator Control API </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
       
        <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  name="elevator_accesss"   disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true}  checked={apiValue.elevator_accesss}/>
      </Stack>
      </Grid>
     
     
      </Grid>
      <Typography sx={mainThemeStyle.popUpWarner} height={40} marginTop={2}>Note :- Program these under manage
location.</Typography>
</Box>

<Box sx={{display: { xs: "block", md: "none" }}}>
           <Grid container spacing={5} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8} >
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={70}> License Plate Recognition(LPR) API </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={70}>Enable Elevator Control API </Typography>
       
      </Stack>
      
      </Grid>
      
      <Grid item xs={4}>
      <Stack direction="column"  spacing={4}>
      <Checkbox  sx={{
         color: 'white',
         '&.Mui-checked': {
           color: 'white',
         },
         '&.Mui-disabled': {
             color: 'gray',
           },
         height:'2.5rem',
         width:'1.56rem',
      }} name="lpr_accesss"   disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true}   checked={apiValue.lpr_accesss} />
      <Checkbox  sx={{
         color: 'white',
         '&.Mui-checked': {
           color: 'white',
         },
         '&.Mui-disabled': {
             color: 'gray',
           },
         height:'2.5rem',
         width:'1.56rem',
         marginLeft:'1rem'
      }}  name="elevator_accesss"   disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true}  checked={apiValue.elevator_accesss}/>
   
      </Stack>
      </Grid>
      
      
      {showUpdateButton && <Button  sx={{
          width: 'auto',
          height: '2.06rem',
          marginTop:'1.2rem',
          button: {
              textTransform: "none"
            },
          fontWeight: '700',
          fontSize: '0.93rem',
          marginLeft:'1rem',
          
          alignItems: 'center',
          color: '#FFFFFF',
          fontFamily: "Lato",
          borderRadius:'0.31rem',
         '&:hover': {
          backgroundColor: 'rgba(109, 195, 252, 1)',
         }
      }} onClick={handleSaveCardFormat}>Save Changes</Button>}   
     <Typography sx={{fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#FF4400',
            marginLeft: '2.56rem',}} height={40} marginTop={2}>Note :- Program these under manage
location.</Typography>
      </Grid>
      
</Box>
      </>
      
      )} */}
      {(currentTab == 3 &&  <>
<Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={4}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> License Plate Recognition(LPR) API </Typography>
        { apiValue.lpr_accesss  == '1' && <Stack direction="row"  spacing={1}>
        
       <ToggleButtonGroup
      disabled = {getDisableValue("215")}
      value={apiValue.open_park ==  1 ? "1" : "0"}
      exclusive
      onChange={handleChangeParking}
      aria-label="Platform"
    >
      <ToggleButton  height = "40px" value="0" selectedColor="#24A0ED" unSelectedColor="gray"  >Open Parking</ToggleButton>
      <ToggleButton   height = "40px" value="1" selectedColor="#24A0ED" unSelectedColor="gray"  >Restricted Parking</ToggleButton>
      
    </ToggleButtonGroup>
       <Tooltip title="Open Parking allows all user and guest vehicles to access any vehicle gate within the property.  Restricted Parking limits all vehicle access only to the specific locations where the user/tenant is registered and authorized">
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
       </Stack>}
       
       {showUpdateButton && <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleLPRUpdateData}  disabled = {getDisableValue("215")} loading={loading}>Save Changes</LoadingButton>}   
      </Stack>
      
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      {/* <Checkbox  sx={mainThemeStyle.formCheckBoxLow} name="lpr_accesss"   disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true}   checked={apiValue.lpr_accesss} />
      <Checkbox  sx={mainThemeStyle.formCheckBoxLow}name="brivo_enable"  disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true} onChange={handlecheckboxCheck} checked={apiValue.brivo_enable}/>
      */}
      <FormControlLabel   sx={ {height:'35px'}} 
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_accesss" /* disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true}  */onChange={handlecheckboxCheck} checked={apiValue.lpr_accesss } />}
        label=""
      />
      
      </Stack>
      </Grid>
     
      
      
      </Grid>
     { apiValue.lpr_accesss  == '1' &&
      <Box sx={{display: { xs: "none", md: "block" }}}>
        
     
           <Grid container spacing={2} padding= '32px'  height={60}> 
           <Grid item xs={0.3}></Grid>
      <Grid item xs={5.2}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Bridge </Typography>
      
      
      
      {/* <Grid item xs={3}>
      <Stack direction="row"  spacing={2}> */}
      <TextField
                       
                        height={40}
                        type= 'text'
                        name="text"
                        placeholder="Friendly Bridge Name"
                        value={brideName}
                        onChange={BridgeInputChange}
                        variant = "filled"
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("219")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                       
                       height={40}
                       type= 'text'
                       name="text"
                       placeholder="Bridge ID"
                       value={brideId}
                       onChange={BridgeIdInputChange}
                       variant = "filled"
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("219")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   />
      <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddBridge} loading={loading}  disabled = {getDisableValue("219")}>Add</LoadingButton>
      </Stack>
      </Grid>
      {/* </Stack>
      </Grid> */}
      <Grid item xs={1.7}>
    
      </Grid>
      <Grid item xs={4.8}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Camera </Typography>
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="text"
                       placeholder="Friendly Camera Name"
                       variant = "filled"
                       value={cameraName}
                       onChange={CameraInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("221")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   />
                   <TextField
                           
                           height={40}
                           type= 'text'
                           name="text"
                           placeholder="Camera ID"
                           variant = "filled"
                           value={cameraId}
                           onChange={CameraIdInputChange}
                           sx={mainThemeStyle.InputMenuItem}
                           InputLabelProps={{ shrink: true }
                         }
                         disabled = {getDisableValue("221")}
                           InputProps={{
                              
                               sx: {
                                   
                                   color:'rgba(135, 135, 135, 0.5)',
                                   height:'33px',
                                   borderRadius: '5px',
                                   backgroundColor: '#121212',
                                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                   padding: '0 0 15px 0',
                                   textIndent: '15px !important',
                                   '&.Mui-disabled': {
             

                                    background: 'rgba(135, 135, 135, 0.5)',
                                  },
                                  
                                  "& input.Mui-disabled": {
                                    color: "white"
                                  }
                                  
                                   
                               },
                           }}
                          
                       />
             <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddCamera}  disabled = {getDisableValue("221")} loading={loading}>Add</LoadingButton>
          
      </Stack>
      </Grid>
      {/* <Grid item xs={3}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
         
           </Stack>
      </Stack>
      </Grid> */}
      
      </Grid>
       <Grid container  padding= '32px'>
       
      <Grid item xs={5}>
      <TblContainerBridge>
                    <TblHeadBridge />
        <TableBody>
         {recordsAfterPagingAndSortingBridge().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
            
              <TableCell  align="left" >
              <TextField
        id="text"
        type="text"
        defaultValue={row.name}
        name = {row.id}
       onChange={handleTableBridgeNameChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("242")}
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
                
                </TableCell>
              <TableCell  align="left" >
              <TextField
        id="text"
        type="text"
        defaultValue={row.bridge_id}
        name = {row.id}
       onChange={handleTableBridgeIdChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
          disabled = {getDisableValue("242")}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
                </TableCell>
              <TableCell align="left">
                
              <Stack direction='row' spacing={2}>
                  <Typography></Typography>  
               <LoadingButton sx={mainThemeStyle.viewLogButton} name={row.id} loading={loadingbtn} onClick={handleBridgeModify} disabled = {getDisableValue("242")}> Save Changes </LoadingButton>
                <LoadingButton loading={loadingbtn}  sx={mainThemeStyle.viewLogButtonRed} onClick={ () => handleBridgeDelete(row.id,row.name)} disabled = {getDisableValue("220")}>Delete</LoadingButton>
                  </Stack> 
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainerBridge>
    
    </Grid>
    <Grid item xs={2}>
    
    </Grid>
    <Grid item xs={5}>
    <TblContainerCamera>
                    <TblHeadCamera />
        <TableBody>
         {recordsAfterPagingAndSortingCamera().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              
              <TableCell  align="left">
              <TextField
        id="text"
        type="text"
        defaultValue={row.name}
        name = {row.id}
       onChange={handleTableCameraNameChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("243")}
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}></TextField></TableCell>
              <TableCell   align="left"> 
              <TextField
        id="text"
        type="text"
        defaultValue={row.camera_id}
        name = {row.id}
       onChange={handleTableCameraIdChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("243")}
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}></TextField>
            </TableCell>
            <TableCell align="left">
               <Stack direction='row' spacing={2}>
              <Typography></Typography>  
               <LoadingButton sx={mainThemeStyle.viewLogButton} name={row.id} loading={loadingbtn} onClick={handleCameraModify} disabled = {getDisableValue("243")}> Save Changes </LoadingButton>
                <LoadingButton loading={loadingbtn}  sx={mainThemeStyle.viewLogButtonRed} onClick={ () => handleCameraDelete(row.id,row.name)} disabled = {getDisableValue("222")}>Delete</LoadingButton>
                  </Stack> </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainerCamera>
   
    </Grid>
      </Grid>
      
    
      <Grid container spacing={3}>
      <Grid item xs={5}>
      <BelowTblPaginationBridge></BelowTblPaginationBridge></Grid>
      <Grid item xs={1}>
      </Grid>
      <Grid item xs={6}>
      <BelowTblPaginationCamera></BelowTblPaginationCamera></Grid></Grid>
      </Box>}
      
      
      {/* <Typography sx={mainThemeStyle.popUpWarner} height={40} marginTop={2}>Note :- Program these under manage
location.</Typography> */}
</Box>

<Box sx={{display: { xs: "block", md: "none" }}}>
           <Grid container spacing={5} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8} >
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={70}> License Plate Recognition(LPR) API </Typography>
        {/* <Typography  sx={mainThemeStyle.popUpLable} height={70}>Enable Elevator Control API </Typography>
        */}
         { apiValue.lpr_accesss  == '1' && <Stack direction="row"  spacing={1}>
        
        <ToggleButtonGroup
       disabled = {getDisableValue("215")}
       value={apiValue.open_park ==  1 ? "1" : "0"}
       exclusive
       onChange={handleChangeParking}
       aria-label="Platform"
     >
       <ToggleButton  height = "40px" value="0" selectedColor="#24A0ED" unSelectedColor="gray" >Open Parking</ToggleButton>
       <ToggleButton   height = "40px" value="1" selectedColor="#24A0ED" unSelectedColor="gray">Restricted Parking</ToggleButton>
       
     </ToggleButtonGroup>
        <Tooltip title="Open Parking allows all user and guest vehicles to access any vehicle gate within the property.  Restricted Parking limits all vehicle access only to the specific locations where the user/tenant is registered and authorized">
       <IconButton  size="small" color="primary" >
         <InfoOutlinedIcon />
       </IconButton>
     </Tooltip>
        </Stack>}
        {showUpdateButton && <LoadingButton  sx={{
          width: 'auto',
          height: '2.06rem',
          marginTop:'1.2rem',
          button: {
              textTransform: "none"
            },
          fontWeight: '700',
          fontSize: '0.93rem',
          marginLeft:'1rem',
          
          alignItems: 'center',
          color: '#FFFFFF',
          fontFamily: "Lato",
          borderRadius:'0.31rem',
         '&:hover': {
          backgroundColor: 'rgba(109, 195, 252, 1)',
         }
      }} onClick={handleLPRUpdateData} loading={loading} disabled = {getDisableValue("215")}>Save Changes</LoadingButton>}   
      </Stack>
      
      </Grid>
      
      <Grid item xs={4}>
      <Stack direction="column"  spacing={4}>
      <FormControlLabel   sx={ {height:'35px'}} 
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_accesss" /* disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true} */ onChange={handlecheckboxCheck} checked={apiValue.lpr_accesss } />}
        label=""
      /> {/* <Checkbox  sx={{
         color: 'white',
         '&.Mui-checked': {
           color: 'white',
         },
         '&.Mui-disabled': {
             color: 'gray',
           },
         height:'2.5rem',
         width:'1.56rem',
         marginLeft:'1rem'
      }}  name="elevator_accesss"   disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true}  checked={apiValue.elevator_accesss}/>
    */}
      </Stack>
      </Grid>
      { apiValue.lpr_accesss  == '1' &&
      <Box sx={{display: { xs: "block", md: "none" }}}>
           <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2} >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Add Bridge </Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={12}>
      <Stack direction="column"  spacing={2}>
      <Stack direction="row"  spacing={2}>
      <TextField
                       
                        height={40}
                        type= 'text'
                        name="text"
                        placeholder="Friendly Bridge Name"
                        value={brideName}
                        onChange={BridgeInputChange}
                        variant = "filled"
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("219")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                       
                       height={40}
                       type= 'text'
                       name="text"
                       placeholder="Bridge ID"
                       value={brideId}
                       onChange={BridgeIdInputChange}
                       variant = "filled"
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("219")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   />
      <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddBridge} loading={loading} disabled = {getDisableValue("219")}>Add</LoadingButton>
      </Stack>
      </Stack>
      </Grid>

      
     
      
      
      </Grid>
      
      
      
      <TblContainerBridge>
                    <TblHeadBridge />
        <TableBody>
         {recordsAfterPagingAndSortingBridge().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
            
            <TableCell  align="left" >
              <TextField
        id="text"
        type="text"
        defaultValue={row.name}
        name = {row.id}
       onChange={handleTableBridgeNameChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("242")}
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
                
                </TableCell>
              <TableCell  align="left" >
              <TextField
        id="text"
        type="text"
        defaultValue={row.bridge_id}
        name = {row.id}
       onChange={handleTableBridgeIdChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("242")}
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
                </TableCell>
              <TableCell align="left">
                
              <Stack direction='row' spacing={2}>
                  <Typography></Typography>  
               <LoadingButton sx={mainThemeStyle.viewLogButton} name={row.id} loading={loadingbtn} disabled = {getDisableValue("242")} onClick={handleBridgeModify}> Save Changes </LoadingButton>
                <LoadingButton loading={loadingbtn}  sx={mainThemeStyle.viewLogButtonRed} disabled = {getDisableValue("220")} onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                  </Stack> 
                </TableCell>   </TableRow>
          ))}
        </TableBody>
      
    </TblContainerBridge>
    
    <BelowTblPaginationBridge></BelowTblPaginationBridge>
    
    <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='100px' > 
    <Grid item xs={12} >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable}> Add Camera </Typography>
      </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
          <TextField
                       
                   height={40}
                   type= 'text'
                   name="text"
                   placeholder="Friendly Camera Name"
                   variant = "filled"
                   value={cameraName}
                   onChange={CameraInputChange}
                   sx={mainThemeStyle.InputMenuItem}
                   InputLabelProps={{ shrink: true }
                 }
                 disabled = {getDisableValue("221")}
                   InputProps={{
                      
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                           '&.Mui-disabled': {
             

                            background: 'rgba(135, 135, 135, 0.5)',
                          },
                          
                          "& input.Mui-disabled": {
                            color: "white"
                          }
                           
                       },
                   }}
                  
               />
               <TextField
                       
                       height={40}
                       type= 'text'
                       name="text"
                       placeholder="Camera ID"
                       variant = "filled"
                       value={cameraId}
                       onChange={CameraIdInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("221")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   />
         <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddCamera}  disabled = {getDisableValue("221")} loading={loading}>Add</LoadingButton>
        </Stack>
      </Stack>
      </Grid>
      
     
      
      
      </Grid>
      
      <TblContainerCamera>
                    <TblHeadCamera />
        <TableBody>
         {recordsAfterPagingAndSortingCamera().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              
              <TableCell  align="left">
              <TextField
        id="text"
        type="text"
        defaultValue={row.name}
        name = {row.id}
       onChange={handleTableCameraNameChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("243")}
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}></TextField></TableCell>
              <TableCell   align="left"> 
              <TextField
        id="text"
        type="text"
        defaultValue={row.camera_id}
        name = {row.id}
       onChange={handleTableCameraIdChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("243")}
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}></TextField>
            </TableCell>
            <TableCell align="left">
               <Stack direction='row' spacing={2}>
              <Typography></Typography>  
               <LoadingButton sx={mainThemeStyle.viewLogButton} name={row.id} loading={loadingbtn} onClick={handleCameraModify}  disabled = {getDisableValue("243")}> Save Changes </LoadingButton>
                <LoadingButton loading={loadingbtn}  sx={mainThemeStyle.viewLogButtonRed} onClick={ () => handleCameraDelete(row.id,row.name)}  disabled = {getDisableValue("222")}>Delete</LoadingButton>
                  </Stack> </TableCell>   </TableRow>
          ))}
        </TableBody>
      
    </TblContainerCamera>
    <BelowTblPaginationCamera></BelowTblPaginationCamera>
      </Box>}
      
     
    {/*  <Typography sx={{fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#FF4400',
            marginLeft: '2.56rem',}} height={40} marginTop={2}>Note :- Program these under manage
location.</Typography> */}
      </Grid>
      
</Box>
      </>
      
      )}
      {(currentTab == 4 &&  <>
<Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Elevator Control API </Typography>
        {showUpdateButton && <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleELESaveData} loading={loading}>Save Changes</LoadingButton>}   
    
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
       
        <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  name="elevator_accesss"   disabled = {getDisableValue("216")}   onChange={handlecheckboxCheck} checked={apiValue.elevator_accesss}/>
      </Stack>
      </Grid>
     
     
      </Grid>

      



      <Typography sx={mainThemeStyle.popUpWarner} height={40} marginTop={2}>Note :- Program these under manage
location.</Typography>
</Box>

<Box sx={{display: { xs: "block", md: "none" }}}>
           <Grid container spacing={5} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8} >
        <Stack direction="column"  spacing={2}>
       {/*  <Typography sx={mainThemeStyle.popUpLable} height={70}> License Plate Recognition(LPR) API </Typography> */}
        <Typography  sx={mainThemeStyle.popUpLable} height={70}>Enable Elevator Control API </Typography>
        {showUpdateButton && <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleELESaveData} loading={loading}>Save Changes</LoadingButton>}   
    
      </Stack>
      
      </Grid>
      
      <Grid item xs={4}>
      <Stack direction="column"  spacing={4}>
        <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  name="elevator_accesss"  disabled = {getDisableValue("216")}   onChange={handlecheckboxCheck} checked={apiValue.elevator_accesss}/>
     
      </Stack>
      </Grid>
      
      
      {/* {showUpdateButton && <Button  sx={{
          width: 'auto',
          height: '2.06rem',
          marginTop:'1.2rem',
          button: {
              textTransform: "none"
            },
          fontWeight: '700',
          fontSize: '0.93rem',
          marginLeft:'1rem',
          
          alignItems: 'center',
          color: '#FFFFFF',
          fontFamily: "Lato",
          borderRadius:'0.31rem',
         '&:hover': {
          backgroundColor: 'rgba(109, 195, 252, 1)',
         }
      }} onClick={handleSaveCardFormat}>Save Changes</Button>}    */}
     <Typography sx={{fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#FF4400',
            marginLeft: '2.56rem',}} height={40} marginTop={2}>Note :- Program these under manage
location.</Typography>
      </Grid>
      
</Box>
      </>
      
      )}
      {(currentTab == 5 &&  <>

        <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Max Vehicle Per User </Typography>
       {showUpdateBrivoButton && <LoadingButton   sx={mainThemeStyle.normalButton} onClick={handleLPRELESaveData} loading={loading}>Save Changes</LoadingButton>}   
      </Stack>
      
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      {/* <Checkbox  sx={mainThemeStyle.formCheckBox} name="lpr_limit"  disabled   checked={apiValue.lpr_limit} /> */}
      <Select
        sx={{ color: 'rgba(135, 135, 135, 0.5)',
        height:'33px',
        top:'4px',
        bottom:'3px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '5px 0 5px 0',
        "& .MuiSvgIcon-root": {
          color: "rgba(135, 135, 135, 0.5)",
        },
        
        '&.Mui-disabled': {
         

          background: 'rgba(135, 135, 135, 0.5)',
        },
        "& input.Mui-disabled": {
          color: "white"
        }
        }}
        disabled = {getDisableValue("217")}  
        name = "lpr_limit"
        value={apiValue.lpr_limit}
      label="lpr_limit"
      onChange={handleInputChange}
      variant="filled"
      
      hiddenLabel
     
      MenuProps={MenuProps}>
        
        {PinLengthHolder.items.map(menuItem => {
              
              return (
                <MenuItem  sx={
                  {
                  
                  color:"#fff",
                  backgroundColor: "#2F323A",
                  borderBottom: '.5px solid grey',
                  fontFamily: "Lato",fontSize:'12px',
              }}
               value={menuItem.label}>
                  {menuItem.label}
                </MenuItem>
              );
            })}
      </Select>
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Max Guest Vehicle Per User</Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
       
       {/*  <Checkbox  sx={mainThemeStyle.formCheckBox}  name="lpr_guest_limit"  disabled  checked={apiValue.lpr_guest_limit}/> */}
       <Select
        sx={{ color: 'rgba(135, 135, 135, 0.5)',
        height:'33px',
        top:'4px',
        bottom:'3px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '5px 0 5px 0',
        "& .MuiSvgIcon-root": {
          color: "rgba(135, 135, 135, 0.5)",
        },
        
        '&.Mui-disabled': {
         

          background: 'rgba(135, 135, 135, 0.5)',
        },
        "& input.Mui-disabled": {
          color: "white"
        }
        }}
        disabled = {getDisableValue("218")}  
        name = "lpr_guest_limit"
        value={apiValue.lpr_guest_limit}
      label="lpr_guest_limit"
      onChange={handleInputChange}
      variant="filled"
      
      hiddenLabel
     
      MenuProps={MenuProps}>
        
        {PinLengthHolder.items.map(menuItem => {
              
              return (
                <MenuItem  sx={
                  {
                  
                  color:"#fff",
                  backgroundColor: "#2F323A",
                  borderBottom: '.5px solid grey',
                  fontFamily: "Lato",fontSize:'12px',
              }}
               value={menuItem.label}>
                  {menuItem.label}
                </MenuItem>
              );
            })}
      </Select>
      </Stack>
      </Grid>
     
     
      </Grid>
      </Box>

      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Max Vehicle Per User </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Max Guest Vehicle Per User</Typography>
      
      </Stack>
      
      </Grid>
      
      <Grid item xs={4}>
      <Stack direction="column"  spacing={2}>
     
      <Select
        sx={{ color: 'rgba(135, 135, 135, 0.5)',
        height:'33px',
        top:'4px',
        bottom:'3px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '5px 0 5px 0',
        "& .MuiSvgIcon-root": {
          color: "rgba(135, 135, 135, 0.5)",
        },
        
        '&.Mui-disabled': {
         

          background: 'rgba(135, 135, 135, 0.5)',
        },
        "& input.Mui-disabled": {
          color: "white"
        }
        }}
        name = "lpr_limit"
        value={apiValue.lpr_limit}
      label="lpr_limit"
      onChange={handleInputChange}
      variant="filled"
      disabled = {getDisableValue("217")}  
      hiddenLabel
     
      MenuProps={MenuProps}>
        
        {PinLengthHolder.items.map(menuItem => {
              
              return (
                <MenuItem  sx={
                  {
                  
                  color:"#fff",
                  backgroundColor: "#2F323A",
                  borderBottom: '.5px solid grey',
                  fontFamily: "Lato",fontSize:'12px',
              }}
               value={menuItem.label}>
                  {menuItem.label}
                </MenuItem>
              );
            })}
      </Select>
      <Select
        sx={{ color: 'rgba(135, 135, 135, 0.5)',
        height:'33px',
        top:'4px',
        bottom:'3px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '5px 0 5px 0',
        "& .MuiSvgIcon-root": {
          color: "rgba(135, 135, 135, 0.5)",
        },
        
        '&.Mui-disabled': {
         

          background: 'rgba(135, 135, 135, 0.5)',
        },
        "& input.Mui-disabled": {
          color: "white"
        }
        }}
        name = "lpr_guest_limit"
        value={apiValue.lpr_guest_limit}
      label="lpr_guest_limit"
      onChange={handleInputChange}
      variant="filled"
      
      hiddenLabel
      disabled = {getDisableValue("218")}  
      MenuProps={MenuProps}>
        
        {PinLengthHolder.items.map(menuItem => {
              
              return (
                <MenuItem  sx={
                  {
                  
                  color:"#fff",
                  backgroundColor: "#2F323A",
                  borderBottom: '.5px solid grey',
                  fontFamily: "Lato",fontSize:'12px',
              }}
               value={menuItem.label}>
                  {menuItem.label}
                </MenuItem>
              );
            })}
      </Select>
      </Stack>
      </Grid>
     
      
      
    
      {showUpdateBrivoButton && <LoadingButton    sx={{
          width: 'auto',
          height: '2.06rem',
          marginTop:'1.2rem',
          button: {
              textTransform: "none"
            },
          fontWeight: '700',
          fontSize: '0.93rem',
          marginLeft:'1rem',
          
          alignItems: 'center',
          color: '#FFFFFF',
          fontFamily: "Lato",
          borderRadius:'0.31rem',
         '&:hover': {
          backgroundColor: 'rgba(109, 195, 252, 1)',
         }
      }} onClick={handleLPRELESaveData} loading={loading}>Save Changes</LoadingButton>}   
     
      </Grid>
      </Box>
      </>
      
      )}
</Box>
<Popup
                title="Brivo API Credential"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
             
             <>
             <Box sx={{
      display: { xs: "none", md: "flex" },
      }}>
             <Grid container spacing={2}> 
      <Grid item >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Admin ID </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Brivo Password </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Brivo Client ID </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>Brivo Client Secret </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>API Key </Typography>
      </Stack>
      </Grid>
      <Grid item >
      <Stack direction="column"  spacing={2}>
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="brivo_username"
                       value={apiValue.brivo_username}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= {isVisible ? 'text' : 'password'}
                       name="brivo_password"
                       value={apiValue.brivo_password}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                           endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    style={{
                                        color:
                                        'rgba(135, 135, 135, 0.5)',
                                        padding: '18px 5px 0 0',
                                    }}
                                    aria-label={
                                        'visibility token'
                                    }
                                    onClick={() => {setIsVisibile(!isVisible)}}
                                >
                                   
                                   {isVisible ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                                    
                                </IconButton>
                            </InputAdornment>
                        ),
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= 'text'
                       name="brivo_clientid"
                       value={apiValue.brivo_clientid}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= {isVisibleSecret ? 'text' : 'password'}
                       name="brivo_clientsecret"

                       value={apiValue.brivo_clientsecret}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                           endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    style={{
                                        color:
                                        'rgba(135, 135, 135, 0.5)',
                                        padding: '18px 5px 0 0',
                                    }}
                                    aria-label={
                                        'visibility token'
                                    }
                                    onClick={() => {setIsVisibileSecret(!isVisibleSecret)}}
                                >
                                   
                                   {isVisibleSecret ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                                    
                                </IconButton>
                            </InputAdornment>
                        ),
                       }}
                      
                   />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name="brivo_apikey"
                       value={apiValue.brivo_apikey}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />
      </Stack>
      </Grid>
      
      </Grid></Box>
      <Box sx={{
      display: { xs: "flex", md: "none" },
      }}>
            <Stack  direction="row"> 
        
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Admin ID </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Brivo Password </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Brivo Client ID </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>Brivo Client Secret </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>API Key </Typography>
      </Stack>
     
      <Stack direction="column"  spacing={2}>
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="brivo_username"
                       value={apiValue.brivo_username}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= {isVisible ? 'text' : 'password'}
                       name="brivo_password"
                       value={apiValue.brivo_password}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                           endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    style={{
                                        color:
                                        'rgba(135, 135, 135, 0.5)',
                                        padding: '18px 5px 0 0',
                                    }}
                                    aria-label={
                                        'visibility token'
                                    }
                                    onClick={() => {setIsVisibile(!isVisible)}}
                                >
                                   
                                   {isVisible ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                                    
                                </IconButton>
                            </InputAdornment>
                        ),
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= 'text'
                       name="brivo_clientid"
                       value={apiValue.brivo_clientid}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />
                    <TextField
                       
                       height={40}
                       type= {isVisibleSecret ? 'text' : 'password'}
                       name="brivo_clientsecret"

                       value={apiValue.brivo_clientsecret}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                           endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    style={{
                                        color:
                                        'rgba(135, 135, 135, 0.5)',
                                        padding: '18px 5px 0 0',
                                    }}
                                    aria-label={
                                        'visibility token'
                                    }
                                    onClick={() => {setIsVisibileSecret(!isVisibleSecret)}}
                                >
                                   
                                   {isVisibleSecret ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                                    
                                </IconButton>
                            </InputAdornment>
                        ),
                       }}
                      
                   />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name="brivo_apikey"
                       value={apiValue.brivo_apikey}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   />
      </Stack>
      </Stack>
      
      </Box>
       {showUpdateBrivoButton && <Button sx={mainThemeStyle.saveButton} onClick={handleSaveBrivoData}>Save Brivo Credentials</Button>}
       </>
               
               </Popup>
   
               <Popup
                   title="PDK API Credential"
                   openPopup={openPopupPDK}
                   setOpenPopup={setOpenPopupPDK}
               >
                
                <>
                <Box sx={{
         display: { xs: "none", md: "flex" },
         }}>
                <Grid container spacing={2}> 
         <Grid item >
           <Stack direction="column"  spacing={2}>
        
         <Typography sx={{ fontFamily: 'Lato',
               fontWeight: '500',
               fontSize: '1rem',
               lineHeight: '1.87rem',
               color:'#878787'}} height={40}> Customer ID </Typography>
   
   <Typography sx={{ fontFamily: 'Lato',
               fontWeight: '500',
               fontSize: '1rem',
               lineHeight: '1.87rem',
               color:'#878787'}} height={40}> System ID </Typography>
   
         </Stack>
         </Grid>
         <Grid item >
         <Stack direction="column"  spacing={2}>
         
         <TextField
                          
                          height={40}
                          type= 'text'
                          name="pdk_organization_id"
                          value={apiValue.pdk_organization_id}
                          sx={mainThemeStyle.InputMenuItem}
                          onChange={handleInputChange}
                          InputLabelProps={{ shrink: true }
                          
                        }
                        variant="filled"
                          InputProps={{
                             
                              sx: {
                                  
                                  color:'rgba(135, 135, 135, 0.5)',
                                  height:'33px',
                                  borderRadius: '5px',
                                  backgroundColor: '#121212',
                                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                  padding: '0 0 15px 0',
                                  textIndent: '15px !important',
                                
                                  '&.Mui-disabled': {
                
   
                                   background: 'rgba(135, 135, 135, 0.5)',
                                 },
                                 
                                 "& input.Mui-disabled": {
                                   color: "white"
                                 }
                                  
                              },
                          }}
                         
                      />
                      
                      <TextField
                          
                          height={40}
                          type= 'text'
                          name="pdk_system_id"
                          value={apiValue.pdk_system_id}
                          sx={mainThemeStyle.InputMenuItem}
                          onChange={handleInputChange}
                          InputLabelProps={{ shrink: true }
                          
                        }
                        variant="filled"
                          InputProps={{
                             
                              sx: {
                                  
                                  color:'rgba(135, 135, 135, 0.5)',
                                  height:'33px',
                                  borderRadius: '5px',
                                  backgroundColor: '#121212',
                                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                  padding: '0 0 15px 0',
                                  textIndent: '15px !important',
                                
                                  '&.Mui-disabled': {
                
   
                                   background: 'rgba(135, 135, 135, 0.5)',
                                 },
                                 
                                 "& input.Mui-disabled": {
                                   color: "white"
                                 }
                                  
                              },
                          }}
                         
                      />
                     
         </Stack>
         </Grid>
         
         </Grid></Box>
         <Box sx={{
         display: { xs: "flex", md: "none" },
         }}>
               <Stack  direction="row"> 
           
           <Stack direction="column"  spacing={2}>
         <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Customer ID </Typography>
         <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> System ID </Typography>
         </Stack>
        
         <Stack direction="column"  spacing={2}>
         <TextField
                          
                          height={40}
                          type= 'text'
                          name="pdk_organization_id"
                          value={apiValue.pdk_organization_id}
                          sx={mainThemeStyle.InputMenuItem}
                          onChange={handleInputChange}
                          InputLabelProps={{ shrink: true }
                          
                        }
                        variant="filled"
                          InputProps={{
                             
                              sx: {
                                  
                                  color:'rgba(135, 135, 135, 0.5)',
                                  height:'33px',
                                  borderRadius: '5px',
                                  backgroundColor: '#121212',
                                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                  padding: '0 0 15px 0',
                                  textIndent: '15px !important',
                                
                                  '&.Mui-disabled': {
                
   
                                   background: 'rgba(135, 135, 135, 0.5)',
                                 },
                                 
                                 "& input.Mui-disabled": {
                                   color: "white"
                                 }
                                  
                              },
                          }}
                         
                      />
                      
                      <TextField
                          
                          height={40}
                          type= 'text'
                          name="pdk_system_id"
                          value={apiValue.pdk_system_id}
                          sx={mainThemeStyle.InputMenuItem}
                          onChange={handleInputChange}
                          InputLabelProps={{ shrink: true }
                          
                        }
                        variant="filled"
                          InputProps={{
                             
                              sx: {
                                  
                                  color:'rgba(135, 135, 135, 0.5)',
                                  height:'33px',
                                  borderRadius: '5px',
                                  backgroundColor: '#121212',
                                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                  padding: '0 0 15px 0',
                                  textIndent: '15px !important',
                                
                                  '&.Mui-disabled': {
                
   
                                   background: 'rgba(135, 135, 135, 0.5)',
                                 },
                                 
                                 "& input.Mui-disabled": {
                                   color: "white"
                                 }
                                  
                              },
                          }}
                         
                      />
                      
                    
         </Stack>
         </Stack>
         
         </Box>
          {showUpdateBrivoButton && <Button sx={mainThemeStyle.saveButton} onClick={handleSavePDKData}>Save PDK Credentials</Button>}
       </>
               
            </Popup>

            
            <Popup
                title="Custom User Field"
                openPopup={openPopupUser}
                setOpenPopup={setOpenPopupUser}
            >
             
             <>

<Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Add Custom Field
                   </Typography>
                   <TextField
        id="text"
        type="text"
      placeholder="Custom Field Name"
      onChange={handleCustomFieldChange}
       variant="filled"
       value={customFieldName}
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
       <NestedButtonDropdown
            itemId={customFieldMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'34px',
            borderRadius: '5px',
            marginTop:'15px',
            marginLeft:'5px',
            marginRight:'5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            textIndent: '15px !important',}}
            data={customFieldMenu}
          />
      {records.length < 5 && <LoadingButton sx={mainThemeStyle.normalButtonDown} loading={loadingbtn} onClick={handleAddCustomField}> Add </LoadingButton>}
      {records.length >= 5 && <LoadingButton sx={mainThemeStyle.normalButtonDown} disabled loading={loadingbtn} onClick={handleAddCustomField}> Add </LoadingButton>}
      </Stack>
      <Typography sx={mainThemeStyle.popUpWarner} height={30}>Note :- Can Add Upto 5 Custom Field.</Typography>
      <Divider sx={{backgroundColor:'white'}} />

      <TblContainer>
                    <TblHead />

        <TableBody>
         {recordsAfterPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
             
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {item.name}</TableCell>
      <TableCell align="left"  sx={mainThemeStyle.cellRow}> {item.type} </TableCell>
      
              
              <TableCell align="left">
                 {/*  <Stack direction='row' spacing={2}>
                  <Typography></Typography>  */} 
               {/* <LoadingButton sx={mainThemeStyle.viewLogButton} name={item.id} loading={loadingbtn} onClick={handlePropertyModify}> Save Changes </LoadingButton>   */}
                <LoadingButton loading={loadingbtn} name={item.id} sx={mainThemeStyle.viewLogButtonRed} onClick={handleDelete}>Delete</LoadingButton>
                 {/*  </Stack> */}  </TableCell>
            </TableRow>
           ))}
        </TableBody>
        </TblContainer>
       
  </>
               
            </Popup>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

export default API;